<template>
  <div class="about-content">
    <img class="banner1" src="../assets/banner1.png">
    <div class="container">
      <div class="title-wrap">
        <div class="title">联系我们</div>
        <div class="text">
          您现在的位置：<span>首页</span>-联系我们
        </div>
      </div>
      <div class="content">
        <img class="map" src="../assets/map.png">
        <div class="cell-group" v-for="(item,index) in list" :key="index">
           <div class="label">{{item.label}}</div>
           <div class="text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConcatContent',
  data(){
    return {
      list:[
        {
          label: '公司：',
          text: '江苏渤海电子商务有限公司'
        },
        {
          label: '电话：',
          text: '0411-87537400'
        },
        {
          label: '传真：',
          text: '0411-87537400'
        },
        {
          label: '地址：',
          text: '江苏省如皋市如城街道福寿路368号'
        },
        {
          label: '编辑：',
          text: '226500'
        },
        {
          label: '公司主页：',
          text: 'http://www.bohaieco.com/'
        }
      ]
    }
  }

}
</script>

<style scoped>
.about-content{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.container{
  width: 1200px;
  background: white;
  padding: 0 24px;
  margin: 40px auto 60px;
  box-sizing: border-box;
  flex: 1;
}
.banner1{
  width: 100%;
  height: 302px;
}
.about-content>.container>.title-wrap{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
  height: 63px;
}
.about-content>.container>.title-wrap>.title{
  font-size: 26px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #D80F20;
  flex: 1;
}
.about-content>.container>.title-wrap>.text{
  font-size: 16px;
  color: #999999;
}
.about-content>.container>.title-wrap>.text>span{
  font-size: 16px;
  color: #666666;
}

.content{
  padding: 14px 0 49px 0
}
.cell-group{
  display: flex;
  align-items: center;
  height: 30px;
}
.cell-group>.label{
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  color: #666666;
}
.cell-group>.text{
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #999999;
}
.map{
 width: 100%;
 height: 400px;
 margin-bottom: 10px;
}
</style>
