<template>
  <div class="footer-content">
    <div>Copyright © Dl-zj.com 江苏渤海电子商务有限公司 All Rights Reserved. </div>
    <div>地址：如皋市如城街道福寿路368号 </div>
    <div>电话：0411-87537400 </div>
  </div>
</template>

<script>
export default {
  name: 'FooterContent'
}
</script>

<style scoped>
.footer-content{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  color: #666666;
  font-size: 13px;
  background: white;
}
</style>
