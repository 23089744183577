<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-03-22 08:49:26
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-04-13 15:01:24
 * @FilePath: \dalian\src\components\AboutContent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="about-content">
    <img class="banner1" src="../assets/banner1.png">
    <div class="container">
      <div class="title-wrap">
        <div class="title">关于江苏渤海</div>
        <div class="text">
          您现在的位置：<span>首页</span>-关于渤海
        </div>
      </div>
      <div class="content">
        <div>
          渤海商品交易所是由天津市政府发起并批准设立，筹备历时三年，2009年9月28日挂牌成立，12月18日开业运营，是天津金融改革创新的重要成果。
        </div>
        <div style="line-height:35px"> 一、大规模综合型现货商品交易所</div>
        <div>
        渤海商品交易所是业内知名的综合型现货商品交易所。截止到2014年4月底，渤商所已上市润滑油、PVC、白银、电解镍、螺纹钢、动力煤、焦炭、天然橡胶等品种80多个，涵盖钢铁产品、
        有色金属、煤炭能源、矿产资源、石油化工、大宗农林、生活消费品七大板块，日交易额200亿元左右，授权服务机构近200家，营业部近1000家，市场配套服务网络覆盖全国各个省级行政区。
        </div>
        <div style="line-height:35px"> 二、创新的BEST现货交易制度</div>
        <div>
        渤海商品交易所创新的BEST现货交易制度（BohaiExchangeSpotTrading），以现货即期交易制度、中间仓补充交收制度、延期交收补偿制度为支撑，
        用“电商平台+交易所”的模式打造大宗商品电子交易平台，通过覆盖全国的市场服务网、资金结算网、仓储物流网和信息宣传网，为生产、消费和经营企业的实务贸易提供功能完备的服务保障，
        是企业“买货、卖货、融资、融货”的理想平台。渤商所不断推出新挂牌商品，为大宗商品相关企业建立新型的销售和采购渠道，降低贸易成本，实现生产者增收，消费者节支，经营者轻松交易做大贸易。
        </div>
        <div style="line-height:35px"> 三、首个跨境交易人民币结算平台试点</div>
        <div>
        2013年4月，渤海商品交易所获中国人民银行批准，成为全国首个跨境交易人民币结算平台试点，国际业务开始起步。10月，央行天津分行召开专项工作会议，进一步大力推动渤商所跨境交易人
        民币结算业务发展。接下来的两年，渤商所上市品种预计超过200个，境外配套服务网络将进一步拓展，打通境内外市场，助力实体企业转型升级。
        </div>
        <div>
          渤海商品交易所将始终坚持服务实体经济的宗旨，坚持标准化、电商化、网络化方向，建成“覆盖全球、服务全球”的综合型现货交易服务平台，在大宗商品全球市场形成中国价格，发出中国声音，
          发挥中国影响力。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutContent'
}
</script>

<style scoped>

.about-content{
  min-height: 100%;
}
.container{
  width: 1200px;
  background: white;
  padding: 0 24px;
  margin: 40px auto 60px;
  box-sizing: border-box;
}
.banner1{
  width: 100%;
  height: 302px;
}
.about-content>.container>.title-wrap{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
  height: 63px;
}
.about-content>.container>.title-wrap>.title{
  font-size: 26px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #D80F20;
  flex: 1;
}
.about-content>.container>.title-wrap>.text{
  font-size: 16px;
  color: #999999;
}
.about-content>.container>.title-wrap>.text>span{
  font-size: 16px;
  color: #666666;
}
.about-content>.container>.content{
  padding: 38px 0;
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
}
</style>
