<template>
  <div id="app">
    <HeaderContent @change="changeHandler"/>
    <div class="content">
      <component :is="componentId"></component>
    </div>
    <FooterContent/>
  </div>
</template>

<script>
import HeaderContent from './components/HeaderContent.vue'
import HomeContent from './components/HomeContent.vue'
import FooterContent from './components/FooterContent.vue'
import AboutContent from './components/AboutContent.vue'
import ProductContent from './components/ProductContent.vue'
import CaseContent from './components/CaseContent.vue'
import NewsContent from './components/NewsContent.vue'
import ConcatContent from './components/ConcatContent.vue'

export default {
  name: 'App',
  components: {
    HeaderContent,
    HomeContent,
    FooterContent,
    AboutContent,
    ProductContent,
    CaseContent,
    NewsContent,
    ConcatContent
  },
  data(){
    return {
      componentId: HomeContent
    }
  },
  methods:{
    changeHandler(componentId){
      this.componentId = componentId
    }
  }
}
</script>

<style>
body,html{
  height: 100%;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content{
  flex: 1;
  overflow-y: auto;
}
</style>
