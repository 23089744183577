<template>
  <div class="about-content">
    <img class="banner1" src="../assets/banner1.png">
    <div class="container">
      <div class="title-wrap">
        <div class="title">新闻动态</div>
        <div class="text">
          您现在的位置：<span>首页</span>-新闻动态
        </div>
      </div>
      <div class="content">
        <div class="cell-group" v-for="(item,index) in list" :key="index">
           <div class="text">{{item.text}}</div>
           <div class="date">{{item.date}}</div>
        </div>
        <div class="page">共有 {{list.length}} 条信息 当前为 1 / 1 页</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsContent',
  data(){
    return {
      list:[
        {
          text: '江苏渤海电子商务有限公司欢迎广大客户',
          date: '2019-07-27'
        },
        {
          text: '可根据用户需求 定制各种电子看板',
          date: '2019-03-04'
        },
        {
          text: '江苏渤海电子商务有限公司更名通知',
          date: '2017-03-15'
        }
      ]
    }
  }

}
</script>

<style scoped>
.about-content{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.container{
  width: 1200px;
  background: white;
  padding: 0 24px;
  margin: 40px auto 60px;
  box-sizing: border-box;
  flex: 1;
}
.banner1{
  width: 100%;
  height: 302px;
}
.about-content>.container>.title-wrap{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
  height: 63px;
}
.about-content>.container>.title-wrap>.title{
  font-size: 26px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #D80F20;
  flex: 1;
}
.about-content>.container>.title-wrap>.text{
  font-size: 16px;
  color: #999999;
}
.about-content>.container>.title-wrap>.text>span{
  font-size: 16px;
  color: #666666;
}

.cell-group{
  display: flex;
  align-items: center;
  height: 52px;
  border-bottom: 1px solid #EBEBEB;
}
.cell-group>.text{
  flex: 1;
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  color: #666666;
}
.cell-group>.date{
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
}
.page{
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 42px;
}
</style>
