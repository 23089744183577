<template>
  <div class="about-content">
    <img class="banner1" src="../assets/banner1.png">
    <div class="container">
      <div class="title-wrap">
        <div class="title">经典案例</div>
        <div class="text">
          您现在的位置：<span>首页</span>-经典案例
        </div>
      </div>
      <div class="content">
        <div class="grid" v-for="(item,index) in list" :key="index">
           <img class="banner1" :src="item.url">
           <div class="name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseContent',
  data(){
    return {
      list:[
        {
          url: require("../assets/case/1@2x.png"),
          name: '新亚'
        },
         {
          url: require("../assets/case/2@2x.png"),
          name: '瑞隆'
        },
         {
          url: require("../assets/case/3@2x.png"),
          name: '丰富'
        },
         {
          url: require("../assets/case/4@2x.png"),
          name: '恒祥'
        },
         {
          url: require("../assets/case/5@2x.png"),
          name: '篮兰'
        },
         {
          url: require("../assets/case/6@2x.png"),
          name: '洁丽雅'
        },
        {
          url: require("../assets/case/7@2x.png"),
          name: '广达集团'
        } ,
         {
          url: require("../assets/case/8@2x.png"),
          name: '孚日集团'
        }
      ]
    }
  }
}
</script>

<style scoped>

.about-content{
  min-height: 100%;
}
.container{
  width: 1200px;
  background: white;
  padding: 0 24px;
  margin: 40px auto 60px;
  box-sizing: border-box;
}
.banner1{
  width: 100%;
  height: 302px;
}
.about-content>.container>.title-wrap{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
  height: 63px;
}
.about-content>.container>.title-wrap>.title{
  font-size: 26px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #D80F20;
  flex: 1;
}
.about-content>.container>.title-wrap>.text{
  font-size: 16px;
  color: #999999;
}
.about-content>.container>.title-wrap>.text>span{
  font-size: 16px;
  color: #666666;
}
.grid{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid>img{
  width: 276px;
  height: 202px;
}
.content{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 0;
}
.name{
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  color: #666666;
  margin-top: 12px;
  text-align: center;
}
</style>
